import { combineReducers } from 'redux';
import userReducer from './user/reducers';
import getStartedReducer from './getstarted/reducers';
import schemaReducer from './schema/reducers';
import clientReducer from './client/reducers';
import connectConfigReducer from './connect_config/reducers';
import transactionReducer from './transaction_state/reducers';
import servicesReducer from './services/reducers';
import providersReducer from './providers/reducers';
import conversationReducer from './conversation/reducers';
import workflowReducer from './workflow/reducers';
import filesReducer from './file_uploads/reducers';
import rulesReducer from './rules/reducers';
import surveyReducer from './survey/reducers';
import paymentsReducer from './payment/reducers';
import encounterReducer from './encounter/reducers';
import articlesReducer from './articles/reducers';
import categoryReducers from "./providers/categoryReducers";
import taskReducers from './tasks/reducers'
import fieldOptionsReducer from './field_option/reducers'
import satisfactionReducers from './satisfaction/reducers'

const initialState = {
    //Have the loading state indicate if it's done getting data.
    loading: false,
    //Have state for error message for recieving an error.
    errorMessage: '',
}

export default combineReducers({
 user: userReducer,
 getstarted: getStartedReducer,
 schema: schemaReducer,
 client: clientReducer,
 connectConfig: connectConfigReducer,
 transactions: transactionReducer,
 services: servicesReducer,
 providers:providersReducer,
 providerCategories:categoryReducers,
 conversation:conversationReducer,
 workflow: workflowReducer,
 files: filesReducer,
 rules: rulesReducer,
 payment: paymentsReducer,
 survey: surveyReducer,
 encounter: encounterReducer,
 articles: articlesReducer,
 tasks: taskReducers,
 fieldOptions: fieldOptionsReducer,
 satisfaction: satisfactionReducers
});